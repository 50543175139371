import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react'


const ContextPersist = createContext({
    updateVideoLink: (link:string) => {},
	videoLink: null,
	updateVideoStatus: (status:boolean) => {},
	videoStatus: false,
	updateClosedVideo: (closed:boolean) => {},
	closedVideo: false,
})
export interface PeristenceProviderProps {
	children: ReactNode
}
const PeristentProvider = ({ children }:PeristenceProviderProps) => {

	
	const [videoLink, setVideolink] = useState("");
	const [videoStatus, setVideoStatus] = useState(false);
	const [closedVideo, setClosedVideo] = useState(false);
	// state: Vido link
	// state: muted
	// state: ?

	/*const changeVideoLink(link: string) => {
		// TODO SetLink
		setVideolink(link);
	}
	*/
	const updateVideoStatus = (status: boolean) =>{
		setVideoStatus(status);
	}

	const updateVideoLink = (link: string) => {
		setVideolink(link)
	}
	const updateClosedVideo = (closed: boolean) => {
		setClosedVideo(closed);
	}

	return (
		<ContextPersist.Provider
		value={{
            updateVideoLink,
			videoLink,
			updateVideoStatus,
			videoStatus,
			updateClosedVideo,
			closedVideo
		}}
		>
			{children}
		</ContextPersist.Provider>
	)
}

export default ContextPersist

export { PeristentProvider }