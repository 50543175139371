// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beint-tsx": () => import("./../src/pages/beint.tsx" /* webpackChunkName: "component---src-pages-beint-tsx" */),
  "component---src-pages-biosalur-tsx": () => import("./../src/pages/biosalur.tsx" /* webpackChunkName: "component---src-pages-biosalur-tsx" */),
  "component---src-pages-dagskra-tsx": () => import("./../src/pages/dagskra.tsx" /* webpackChunkName: "component---src-pages-dagskra-tsx" */),
  "component---src-pages-fordrykkur-tsx": () => import("./../src/pages/fordrykkur.tsx" /* webpackChunkName: "component---src-pages-fordrykkur-tsx" */),
  "component---src-pages-framleidslaogframleidslustyring-tsx": () => import("./../src/pages/framleidslaogframleidslustyring.tsx" /* webpackChunkName: "component---src-pages-framleidslaogframleidslustyring-tsx" */),
  "component---src-pages-fyrstahaed-bistro-facilities-tsx": () => import("./../src/pages/fyrstahaed-bistro-facilities.tsx" /* webpackChunkName: "component---src-pages-fyrstahaed-bistro-facilities-tsx" */),
  "component---src-pages-herbergi-tsx": () => import("./../src/pages/herbergi.tsx" /* webpackChunkName: "component---src-pages-herbergi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innova-tsx": () => import("./../src/pages/innova.tsx" /* webpackChunkName: "component---src-pages-innova-tsx" */),
  "component---src-pages-innovationogcrossindustryservice-tsx": () => import("./../src/pages/innovationogcrossindustryservice.tsx" /* webpackChunkName: "component---src-pages-innovationogcrossindustryservice-tsx" */),
  "component---src-pages-leidbeiningar-tsx": () => import("./../src/pages/leidbeiningar.tsx" /* webpackChunkName: "component---src-pages-leidbeiningar-tsx" */),
  "component---src-pages-onnurhaedogit-tsx": () => import("./../src/pages/onnurhaedogit.tsx" /* webpackChunkName: "component---src-pages-onnurhaedogit-tsx" */),
  "component---src-pages-thridjahaedogcrossindustry-tsx": () => import("./../src/pages/thridjahaedogcrossindustry.tsx" /* webpackChunkName: "component---src-pages-thridjahaedogcrossindustry-tsx" */),
  "component---src-pages-upptokur-tsx": () => import("./../src/pages/upptokur.tsx" /* webpackChunkName: "component---src-pages-upptokur-tsx" */),
  "component---src-templates-event-tsx": () => import("./../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */)
}

