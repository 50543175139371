import React, { CSSProperties, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

interface DefaultProps { [key:string]: CSSProperties }
const defaultProps:DefaultProps = {
	styleDefault: {
		position: "absolute",
		width: "100%",
		height: "100%",
		top: "0",
		left: "0",
		display: "none"
	},
	styleFollow: {
		position: "fixed",
		left: "0",
		bottom: "0",
		width: "330px",
	}
}

interface FollowMeIframeProps {
	styleDefault?: CSSProperties,
	styleFollow?: CSSProperties
	follow?: boolean
	ratio: string
	children: ReactNode
	path: string
}
export default ({
	styleDefault = defaultProps.styleDefault,
	styleFollow = defaultProps.styleFollow,
	follow,
	ratio,
	children,
	path
}: FollowMeIframeProps) => {
	return (
		<Container style={follow ? styleFollow : styleDefault }>
			{follow && (
				<FollowMeIframeRatio ratio={ratio}>
				{children}
			</FollowMeIframeRatio>
			)}

		</Container>
	)
}

const Container = styled.div`
`

interface ContainerRatioProps { ratio: string }
export const FollowMeIframeRatio = styled.div<ContainerRatioProps>`
	background: black;
	padding-bottom: ${({ ratio }) => ratio}%;
	height: 0;
	width: 100%;
	position: relative;
	/* overflow: hidden; */
	embed, 
	iframe, 
	object, 
	video {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}
`