import React, { createContext, ReactNode, useContext, useState, useEffect, useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { PeristentProvider } from "./context/PersistentContext";
import PeristenceContext from "./context/PersistentContext";
import CloseSVG from '../Svg/Close';

import { 
	mediaMax, 
	number_size as breakpoints
} from '../../utils/breakpoints';

import FollowMeIframe from '../FollowMeIframe'
import { string } from 'prop-types';
// TODO Provider

// 1. You go to live
// 2. Something is live
// 3. Populate persist video with link
// 4. Show Persist video
// 5. Navigate to other page.
// 6. Video is still there.
// Condition
// A. User closes video => gone forever or until user goes back to live
// B. User mutes
// C. User fullscreen
// Unknown
// F. Slido ?
const HelloWorld = (rest : any) => {
	const { 
		updateVideoLink, 
		videoLink,
		updateVideoStatus, 
		videoStatus,
		updateClosedVideo, 
		closedVideo
	} = useContext(PeristenceContext)

	let uri = null;
	if(rest.rest.uri !== undefined){
		uri = rest.rest.uri;
	}

	const ratio = "56.25"
	useEffect(() => {
		//Helpers for live
		//console.log('uri', uri);
		//console.log('closedVideo', closedVideo)
		//console.log('window check', typeof window);
		//console.log(window.matchMedia(`(max-width: ${breakpoints.tablet}px)`).matches)
		if(typeof window !== "undefined" && window.matchMedia(`(max-width: ${breakpoints.tablet}px)`).matches){
			updateVideoStatus(false)
		} else if(uri === "/beint" || uri === null){
			updateVideoStatus(false)
			updateClosedVideo(false)
		} else if(closedVideo) {
			updateVideoStatus(false)
		} else {
			updateVideoStatus(true)
		}
	})	

	const closeFollow = () => {
		updateClosedVideo(true);
	}
	return(
		<div>
		{videoLink &&(
		<ContainerIframeVideo>
			<FollowMeIframe path={uri} ratio={ratio} follow={videoStatus}>
				<CloseButtonIframe 
					onClick={closeFollow}>
						<CloseSVG fill="black" width="25px" className="button-close" />
				</CloseButtonIframe >
				<iframe
					src={videoLink}
					frameBorder="0"
				>
				</iframe>
			</FollowMeIframe>
		</ContainerIframeVideo>
		)}
		</div>
	)
}

export interface LayoutPersistentProps {
	children: ReactNode
}

export default ( {children, ...rest}:LayoutPersistentProps ) => {
	
	return(
		<PeristentProvider>
			{ children }
			<HelloWorld rest={rest} />
		</PeristentProvider>
	)
}

const CloseButtonIframe = styled.div`
	position:absolute;
	z-index: 1;
	right: 0;
	top: 0;
	cursor: pointer;
	width: max(28px,1.388vw);
	height: max(28px,1.388vw);
	transform: translateX(100%);
	background: white;
	border: 1px solid transparent;

	&:hover{
		border-left: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		border-bottom-left-radius: 5px;
	}

	@media( max-width: ${breakpoints.tabletL}px){
		opacity: 1;
	}
`

export const ContainerIframeVideo = styled.div`
	width: 54vw;
	position: relative;
	margin-right: 1.66667vw;
	z-index: 1;	
`