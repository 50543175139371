export interface LiveProps {
  date: string,
  time: string,
  room? : any,
  id? : string
}

const fixedLengthOfEvents = 15;

export const getLiveEvent = (data : LiveProps[]) => {
  const rightNow = new Date();

  const activeDude = data.filter(speaker => {
    let speakerDate = new Date(speaker.date);
    let speakerStartHour = parseInt(speaker.time.substring(0,2));
    let speakerStartMinute = parseInt(speaker.time.substring(3,5));
    
    let speakerCombinedDateTime = new Date(speakerDate.getFullYear(),speakerDate.getMonth(), speakerDate.getDate(),speakerStartHour, speakerStartMinute);

    //Check if the day is today
    if(speakerCombinedDateTime.toLocaleDateString() === rightNow.toLocaleDateString()){
      let speakerStart = speakerCombinedDateTime.getTime();
      let speakerEnd = new Date(speakerCombinedDateTime.getTime() + fixedLengthOfEvents*60000).getTime();

      if(speakerStart <= rightNow.getTime() && speakerEnd >= rightNow.getTime()){
        return true;
      }
    }
    return false;
  })
  return activeDude;
}