export const number_size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 550,
  tablet: 768,
  tabletL: 1024,
  laptop: 1280,
  laptopL: 1440,
  desktop: 240,
}

export const mediaMin = {
  mobileS: `(min-width: ${number_size.mobileS + 1}px)`,
  mobileM: `(min-width: ${number_size.mobileM + 1}px)`,
  mobileL: `(min-width: ${number_size.mobileL + 1}px)`,
  tablet: `(min-width: ${number_size.tablet + 1}px)`,
  tabletL: `(min-width: ${number_size.tabletL + 1}px)`,
  laptop: `(min-width: ${number_size.laptop + 1}px)`,
  laptopL: `(min-width: ${number_size.laptopL + 1}px)`,
  desktop: `(min-width: ${number_size.desktop + 1}px)`,
  desktopL: `(min-width: ${number_size.desktop + 1}px)`,
}
export const mediaMax = {
  mobileS: `(max-width: ${number_size.mobileS}px)`,
  mobileM: `(max-width: ${number_size.mobileM}px)`,
  mobileL: `(max-width: ${number_size.mobileL}px)`,
  tablet: `(max-width: ${number_size.tablet}px)`,
  tabletL: `(max-width: ${number_size.tabletL}px)`,
  laptop: `(max-width: ${number_size.laptop}px)`,
  laptopL: `(max-width: ${number_size.laptopL}px)`,
  desktop: `(max-width: ${number_size.desktop}px)`,
  desktopL: `(max-width: ${number_size.desktop}px)`,
}
