import React from "react";
import { SVG } from '../../types/svg.ts'

export default ({
	height,
	width,
	style,
	className,
	fill,
}: SVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 26 26"
			className={className}
			height={height}
			width={width}
			style={style}
			fill={fill}
    >
      <g>
        <g transform="translate(-1375 -40)">
          <g transform="translate(1365 30)">
            <g transform="rotate(45 -.707 32.435)">
              <path d="M9 0a1 1 0 011 1v7h7a1 1 0 010 2h-7v7a1 1 0 01-2 0v-7H1a1 1 0 110-2h7V1a1 1 0 011-1z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
